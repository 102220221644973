.rbc-toolbar .rbc-btn-group button{
    background-color: #ff5807 !important;
    color: white !important;
}

.rbc-toolbar .rbc-btn-group button:active, .rbc-toolbar .rbc-btn-group button.rbc-active, .rbc-toolbar .rbc-btn-group button:hover {
    background-color: #00497f !important;
    color: #FFF !important;
}

.rbc-btn-group > button:first-child:not(:last-child) {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    
}

.rbc-btn-group > button:last-child:not(:first-child) {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}