::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #fff;
}

::-webkit-scrollbar-track {
    background-color: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background-color: #FF5702;
}

table tr th,
table tr td {
    padding-right: 0 !important;
}

/* th {
    position: inherit !important;
} */

.marginTopLogo {
    margin-top: 90px;
}

.cursorPointer {
    cursor: pointer !important;
}
.columnComments {
    display: flex;
    align-items: center;
}
.textEllipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
}

.blockCenter{
    display: block;
    text-align: center;
}

/* Menu */
.itemText div ~ div{
    padding-left: 0;
}
.itemText div ~ div span{
    font-size: .9rem;
}

span.rc-draggable-list-handles{
    visibility: hidden;
}